import React, { useState } from "react";
import styled from "styled-components";
import { sources } from "../sources";
import { useSearchParams } from "react-router-dom";
import Source from "./Source";
import {
  ArrowDropDown,
  ArrowDropUp,
  Share,
  SortByAlpha,
} from "@mui/icons-material";

import {//useState,
useEffect} from 'react';

const Container = styled.div`
  width: 90%;
  margin: 5%;
  display: flex;
  flex-direction: column;
`;

const SourcesHeader = styled.h1`
  text-align: center;
`;

const SourcesOptionsContainer = styled.div`
  margin: 20px 20px 0px 20px;
  display: flex;
  flex-direction: column;
`;

const SourcesOptionsHeader = styled.h2`
  font-size: 20px;
  text-align: center;
`;

const SourcesOptionsFields = styled.div`
  display: flex;
  text-align: center;
`;

const SourcesOptionsField = styled.label`
  flex: 1;
`;

const BackButtonContainer = styled.div`
  padding: 10px 0px;
`;

const BackButton = styled.button``;

const TableWrapper = styled.div`
  padding: 2px;
  border-width: 2px;
  border-style: solid;
  border-color: darkgray;
  border-radius: 6px;
  background-color: white;
  overflow-x: scroll;
  height: 80vh;
  // max-height: 80vh;
`;

const TableOptions = styled.div`
  position: sticky;
  left: 20px;
  display: flex;
  flex-direction: column;
  margin: 20px 20px 0px 20px;
  flex: 1;
`;

const TableOptionsHeader = styled.div`
  text-align: center;
  font-size: 20px;
`;

const TableOptionsFields = styled.div`
  width: 100%;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fit, minmax(min-content, 150px));
  text-align: left;
`;

const TableOptionsField = styled.label`
  flex: 1;
  white-space: nowrap;
  display: ${(props) => props.visible === false && "none"};
`;

const TableInfo = styled.div`
  padding: 5px;
`;

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 1;
`;

const TableBody = styled.tbody``;

const TableHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TableHeader = styled.th`
  padding: 2px 4px;
  display: ${(props) => props.visible === false && "none"};
  white-space: nowrap;
  max-width: 15vw;
  background-color: white;
  border-bottom: 2px solid black;
`;

const TableHeaderText = styled.span`
  padding: 0px 4px 0px 0px;
  float: left;
`;

const TableHeaderSort = styled.span`
  cursor: pointer;
  padding: 0px 0px 0px 4px;
  float: right;
`;

const TableSearch = styled.input`
  width: 100%;
  padding: 0px;
  margin: 0px;
`;

const TableRow = styled.tr`
  background-color: ${(props) =>
    props.rowIndex % 2 === 1 ? "#ffffff" : "#e7e7e7"};
`;

const TableData = styled.td`
  padding: 2px 4px;
  display: ${(props) => props.visible === false && "none"};
  text-align: ${(props) => (props.align === "center" ? "center" : "left")};
  max-width: 15vw;
`;

const TableDataDiv = styled.div`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  //  white-space: nowrap;
  //  overflow: scroll;
`;

const WorldcatLink = styled.a``;



const Sources = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const headers = [
    "ID",
    "Title",
    "Author",
    "Year",
    "Target",
    "Analysis",
    "Type",
    "Worldcat",
  ];

  const headersToFields = {
    ID: "source_citation_id",
    Title: "source_title",
    Author: "source_author",
    Year: "source_year",
    Target: "source_target_language",
    Analysis: "source_analysis_language",
    Type: "source_type",
    Worldcat: "source_link",
  };

  const [headersViews, setHeadersViews] = useState(
    headers.reduce((accumulator, header) => {
      return { ...accumulator, [header]: true };
    }, {})
  );

  const getInputField = (field) => {
    return (
      <input
        type="checkbox"
        checked={headersViews[field]}
        onChange={() =>
          setHeadersViews({ ...headersViews, [field]: !headersViews[field] })
        }
      ></input>
    );
  };

  const getSource = (sourceID) => {
    console.log(sourceID);
    const source = (merchants ? merchants : []).reduce(
      (accumulator, source) =>
        source["source_citation_id"] === sourceID ? source : accumulator,
      {}
    );
    return <Source {...source} />;
  };

  const setSource = (sourceID) => {
    setSearchParams({ ...searchParams, sourceID: sourceID });
  };

  const [merchants, setMerchants] = useState(false);

  function getMerchant() {
    fetch('http://ec2-18-116-90-129.us-east-2.compute.amazonaws.com:3001')
      .then(response=> {
        return response.json();
      })
      .then(data => {
        setMerchants(data)
      });
  }

  useEffect(() => {
    getMerchant();
  }, []);

  const clickSort = (clickedHeader) => {
    console.log(headersSorts);
    let newSorts = {...headersSorts}
    if (headersSorts.hasOwnProperty(clickedHeader)) {
      newSorts[clickedHeader] = (headersSorts[clickedHeader] + 1) % 3;
      if (newSorts[clickedHeader] === 0) {
        delete newSorts[clickedHeader];
      }
    } else {
      newSorts = {...newSorts, [clickedHeader]: 1}
    }
    // let newSorts = headerSorts.hasOwnProperty(clickedHeader) ? 
    // let newSorts = foundHeaders.reduce((accumulator, header) => {
    //   return { ...accumulator, [header]: 0 };
    // }, {});
    // newSorts[clickedHeader] = ((headersSorts[clickedHeader] == null ? 0 : headersSorts[clickedHeader]) + 1) % 3;
    console.log(newSorts);
    console.log(headersSorts);
    setHeadersSorts({ ...newSorts });
    setMerchants(merchants.sort((entry1, entry2) => sortData(entry1, entry2, newSorts)));
  };

  const getIcon = (header) => {
    return headersSorts[header] === 2 ? (
      <ArrowDropDown fontSize="small" />
    ) : headersSorts[header] === 1 ? (
      <ArrowDropUp fontSize="small" />
    ) : (
      <SortByAlpha fontSize="small" />
    );
  };

  const determineHeaders = (entry) => {
    let newHeaders = headers
      .filter((header) => entry.hasOwnProperty(headersToFields[header]))
      .map((header) => header);
    return newHeaders;
  };
  const foundHeaders = determineHeaders(
    merchants.length > 0 ? merchants[0] : {}
  );

  const [headersSorts, setHeadersSorts] = useState(
    foundHeaders.reduce((accumulator, header) => {
      return { ...accumulator, [header]: 0 };
    }, {})
  );
  
  const collectHeaders = () => {
    return (
      <React.Fragment>
        {headers
          .filter((header) => (merchants.length > 0 ? merchants[0] : {}).hasOwnProperty(headersToFields[header]))
          .map((header) => (
            <TableHeader
              key={header}
              visible={headersViews[header]}
            >
              <TableHeaderWrapper>
                <TableHeaderText>
                  {header}
                </TableHeaderText>
                <TableHeaderSort onClick={() => clickSort(header)}>
                  {getIcon(header)}
                </TableHeaderSort>
              </TableHeaderWrapper>
            </TableHeader>
          ))}
      </React.Fragment>
    )
  }

  const collectData = (entry, index) => {
    return (
      <React.Fragment key={index}>
        {headers.filter((header) =>
            entry.hasOwnProperty(headersToFields[header])
          )
          .map((header, index) => (
            <TableData
              key={index}
              visible={headersViews[header]}
              align={header === "Worldcat" && "center"}
            >
              <TableDataDiv>
                {header === "Worldcat" ? (
                  entry[headersToFields[header]] === "NA" ? (
                    "NA"
                  ) : (
                    <WorldcatLink
                      target="_blank"
                      rel="noopener noreferrer"
                      href={entry[headersToFields[header]]}
                    >
                      <Share style={{ fill: "blue" }} />
                    </WorldcatLink>
                  )
                ) : (
                      <React.Fragment key={index}>
                        {headersToFields[header] === "source_citation_id" ? (
                          <span
                            onClick={() => setSource(entry[headersToFields[header]])}
                            style={{
                              color: "blue",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            {entry[headersToFields[header]]}
                          </span>
                        ) : (
                          <span>{entry[headersToFields[header]]}</span>
                        )}
                      </React.Fragment>
                )}
              </TableDataDiv>
            </TableData>
          ))}
      </React.Fragment>
    );
  };

  const sortData = (entry1, entry2, sortsArray) => {
    let rval = Object.entries(sortsArray).map(([property]) => {
      if (sortsArray[property] === 1) {
        return entry1[headersToFields[property]].localeCompare(entry2[headersToFields[property]]);
      } else if (sortsArray[property] === 2) {
        return entry2[headersToFields[property]].localeCompare(entry1[headersToFields[property]]);
      } else {
        return 0;
      }
    }).reduce((prev, curr) => {return prev !== 0 ? prev : curr}, 0);
    console.log(rval);
    return rval;
  };

  return (
    <Container>
      <SourcesHeader>Sources</SourcesHeader>
      <SourcesOptionsContainer>
        <SourcesOptionsHeader>Options</SourcesOptionsHeader>
        <SourcesOptionsFields>
          <SourcesOptionsField>
            {getInputField("Target")}
            View Target Language
          </SourcesOptionsField>
          <SourcesOptionsField>
            {getInputField("Analysis")}
            View Analysis Language
          </SourcesOptionsField>
        </SourcesOptionsFields>
      </SourcesOptionsContainer>
      {!searchParams.get("sourceID") ? (
        <TableWrapper>
          <Table>
            <TableHead>
              <TableRow rowIndex={1}>{collectHeaders()}</TableRow>
            </TableHead>
            <TableBody>
              {(merchants ? merchants: [])
              // .filter((entry) => filterData(entry))
              //            .slice(0, 100)
              // .sort((entry1, entry2) => sortData(entry1, entry2))
              .map((entry, index) => (
                <TableRow rowIndex={index} key={index}>
                  {collectData(entry, index)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      ) : (
        <React.Fragment>
          <BackButtonContainer>
            <BackButton onClick={() => setSource("")}>Back</BackButton>
          </BackButtonContainer>
          {getSource(searchParams.get("sourceID"))}
        </React.Fragment>
      )}
    </Container>
  );
};

export default Sources;
