import styled from "styled-components";

const Container = styled.div`
  height: 30px;
  background-color: darkred;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: 14px;
  font-weight: 500;
`;

const FooterItalics = styled.i`
  color: white;
`;

function printDate() {
  var today = new Date();
  var dd = String(today.getDate());
  var mm = today.toLocaleString('default', { month: 'long' });
  var yyyy = today.getFullYear();
  var today = dd + ' ' + mm + ' ' + yyyy;
  return today;
}

const Footer = () => {
  return (
    <div>
      <Container>
        Last updated: {printDate()}.
        {/* Please feel free to reach out to Blaine Billings (blaine.t.billings@gmail.com) for information or questions regarding this project. */}
        {/* Citation: Billings, Blaine. 2023.{" "}
        <FooterItalics>
          Electronic handbook of Sumatran linguistics
        </FooterItalics>
        . (https://www.e-hsl.com/) */}
      </Container>
    </div>
  );
};

export default Footer;
